import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { getPlaiceholder } from 'plaiceholder';
import { SEO } from '@/components';
import LogIn from '@/containers/Login';
import { getLoginImages } from '@/utils/images';
import { getServerSession } from './api/auth/_utils';

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  req,
  res
}) => {
  const images = await getLoginImages(getPlaiceholder);
  const session = await getServerSession({ req, res });
  if (session) {
    return {
      redirect: {
        destination: '/dashboard',
        permanent: false
      }
    };
  }

  return {
    props: {
      // @ts-expect-error wontfix
      ...(await serverSideTranslations(locale, ['login', 'common'])),
      locale,
      reloadOnPrerender: process.env.NODE_ENV !== 'production',
      images
    }
  };
};

const AppLogin = ({ images }) => {
  const { isReady } = useRouter();
  if (!isReady) return null;
  return (
    <>
      <SEO title="Sign in">
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_DOMAIN}/login`}
          hrefLang="en"
        />
        <link
          rel="alternate"
          hrefLang="es"
          href={`${process.env.NEXT_PUBLIC_DOMAIN}/es/login`}
        />
        <link
          rel="alternate"
          hrefLang="fr"
          href={`${process.env.NEXT_PUBLIC_DOMAIN}/fr/login`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`${process.env.NEXT_PUBLIC_DOMAIN}/en/login`}
        />
      </SEO>
      <LogIn images={images} />
    </>
  );
};

export default AppLogin;
