import { useState } from 'react';
import { signIn } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { SmatIsoLogo } from '@/components/Logos/Logos';
import MyModal from '@/components/Modals';
import { AddressNotAllowe } from '@/components/Modals/NotAllowed';
import { SignMessage } from '@/components/Siwe/SignMessage';
import LoginLayout from '@/layouts/login.layout';
import { SIGN_IN_TYPE } from '@/services/auth.service';
import { useToggle } from '@/utils/hooks';
import { Box, Button, Stack, Typography } from '@mui/material';

const LogIn = ({ images }) => {
  const { t } = useTranslation();
  const { on, setOff, setOn } = useToggle();
  const { query } = useRouter();
  const [openModal, setOpenModal] = useState<boolean>(!!query?.notAllowed);

  const handleModal = (state: boolean) => setOpenModal(state);

  return (
    <LoginLayout images={images}>
      <MyModal
        open={openModal}
        onClose={() => handleModal(false)}
        title={'Confirm paymend id'}
        description={'Transaction payment'}
      >
        <AddressNotAllowe onClose={() => handleModal(false)} />
      </MyModal>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1.5rem'
        }}
      >
        <SmatIsoLogo
          images={images}
          sx={{
            filter: 'drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.35))'
          }}
        />
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography variant={'h3'} align="center">
              Welcome to
              <br />
              <Box component={'span'} sx={{ color: 'primary.main' }}>
                SMAT Token Dashboard
              </Box>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Button
              variant={'contained'}
              fullWidth
              onClick={() =>
                signIn(SIGN_IN_TYPE.Zitadel, {
                  callbackUrl: `${process.env.NEXT_PUBLIC_DOMAIN}/dashboard`
                })
              }
              sx={{
                paddingInline: '6rem'
              }}
            >
              {t('common:signIn')}
            </Button>
            <SignMessage open={on} onClose={setOff} />
            <Button
              variant={'contained'}
              onClick={() => setOn()}
              sx={{
                paddingInline: '6rem',
                width: 'fit-content'
              }}
            >
              Sign in with Ethereum
            </Button>
          </Box>
        </Stack>
      </Box>
    </LoginLayout>
  );
};

export default LogIn;
