import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-toastify';
import { useAccount, useNetwork, useSignMessage } from 'wagmi';
import { SignInWithSIWE, SignSIWEMessage } from '@/services/auth.service';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Card, Divider, Modal } from '@mui/material';
import WalletLogIn from '../Modals/WalletLogin';

export const SignMessage = ({ onClose, open }) => {
  const { signMessageAsync } = useSignMessage();
  const { chain: activeChain } = useNetwork();
  const { address, isConnected } = useAccount();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    try {
      if (!address) {
        throw new Error('No wallet address found');
      }
      if (!activeChain) {
        throw new Error('No active chain found');
      }
      setLoading(true);
      const signRes = await SignSIWEMessage(
        address,
        activeChain,
        signMessageAsync
      );
      if (signRes?.signature && signRes?.message) {
        SignInWithSIWE(signRes.signature, signRes.message);
      } else {
        throw new Error('No signature or message found');
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      {isConnected ? (
        <Card
          sx={{
            position: 'absolute' as const,
            width: { xs: '90%', sm: 'fit-content' },
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 5,
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            padding: '2rem',
            borderRadius: '10px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: 'primary.main',
              fontWeight: 'bold',
              '& svg': {
                cursor: 'pointer'
              }
            }}
          >
            <span>Login</span>
            <CloseIcon onClick={onClose} />
          </Box>
          <Divider sx={{ my: '0.5rem' }} />
          <div>
            <h3>
              {activeChain?.unsupported
                ? t('login:wallet.unsupportedNetwork')
                : t('login:wallet.pleaseSignMessage')}
            </h3>
            <p>
              {activeChain?.unsupported
                ? t('login:wallet.switchNetwork')
                : t('login:wallet.signExplanation')}
            </p>
          </div>
          {!activeChain?.unsupported && (
            <LoadingButton
              loading={loading}
              variant={'contained'}
              onClick={handleLogin}
              sx={{
                '&.MuiLoadingButton-root.Mui-disabled': {
                  bgcolor: 'primary.main'
                }
              }}
            >
              {t('login:wallet.signMessage')}
            </LoadingButton>
          )}
        </Card>
      ) : (
        <Box sx={style}>
          <WalletLogIn onClose={onClose} />
        </Box>
      )}
    </Modal>
  );
};
const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  width: { xs: '90%', sm: 'fit-content' }
};
