import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, Divider, IconButton, Typography } from '@mui/material';
import { styles } from './styles';

export const AddressNotAllowe = ({ onClose }) => {
  return (
    <Card sx={styles.root}>
      <Box sx={styles.login}>
        <Typography variant="subtitle1" color="primary.main">
          Account Logout Alert
        </Typography>
        <IconButton color={'primary'} sx={{ boxShadow: 0 }} onClick={onClose}>
          <CloseIcon width={24} />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ p: '1rem 2rem 2rem 2rem' }}>
        <Typography
          align={'justify'}
          variant={'body1'}
          sx={{ lineHeight: 1.6, py: '1rem' }}
        >
          Your account has been automatically logged out due to a change in
          login information. If you switched accounts or made updates, please
          log in again to continue. For security reasons, we require
          re-verification when login details change. This ensures secure access
          and prevents unauthorized use.
        </Typography>
      </Box>
    </Card>
  );
};
