import Image from 'next/legacy/image';
import { Box, Container } from '@mui/material';

const LoginLayout = ({ children, images }) => {
  return (
    <Box sx={{ minWidth: '100vw', minHeight: '100vh' }}>
      <Container
        maxWidth={'xl'}
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '60% 40%' },
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          scrollSnapType: { xs: 'y mandatory', md: 'unset' }
        }}
      >
        <Box
          component={'main'}
          sx={{
            scrollSnapAlign: { xs: 'start', md: 'unset' }
          }}
        >
          {children}
        </Box>
      </Container>
      <BackgroundImage image={images.waves} />
    </Box>
  );
};

export const BackgroundImage = ({
  image,
  position = 'right',
  rotate = true
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        inset: {
          xs: `0 ${position === 'left' ? '25%' : 0} 0 ${
            position === 'right' ? '25%' : 0
          }`,
          md: `0 ${position === 'left' ? '55%' : 0} 0 ${
            position === 'right' ? '55%' : 0
          }`
        },
        zIndex: -1,
        opacity: 0.6,
        ...(rotate && { transform: 'rotate(180deg)' }),
        '& > span > img': {
          '-webkit-mask-image': `-webkit-gradient(linear, ${position} top, ${position} bottom, from(rgba(0,0,0,1) 80&), to(rgba(0,0,0,0)))`,
          'mask-image': `linear-gradient(to ${position}, rgba(0,0,0,1) 80%, rgba(0,0,0,0))`
        }
      }}
    >
      <Image layout={'fill'} alt={''} {...image} placeholder={'blur'} />
    </Box>
  );
};

export default LoginLayout;
